import { httpClient } from "../../shared/services";

const { VUE_APP_ZADI_API_BASE_URL } = process.env;
const zadiApiBaseUrl = VUE_APP_ZADI_API_BASE_URL;

export default {
  uploadImage(formData) {
    const url = `${zadiApiBaseUrl}v1/files/upload`;
    return httpClient.post(url, formData);
  },
  shareImage({ entityType, imageLink, entityId }) {
    const url = `${zadiApiBaseUrl}v1/share-entity`;
    return httpClient.post(url, {
      entityType,
      imageLink,
      entityId
    })
  },
}
