<template>
  <div :class="['loader', isCover ? 'cover' : null]">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" :style="{ width: size }">
      <circle fill="#F4ACBF" stroke="#F4ACBF" stroke-width="15" r="15" cx="40" cy="65">
        <animate attributeName="cy" calcMode="spline" dur="1" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1"
          repeatCount="indefinite" begin="-.4"></animate>
      </circle>
      <circle fill="#F4ACBF" stroke="#F4ACBF" stroke-width="15" r="15" cx="100" cy="65">
        <animate attributeName="cy" calcMode="spline" dur="1" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1"
          repeatCount="indefinite" begin="-.2"></animate>
      </circle>
      <circle fill="#F4ACBF" stroke="#F4ACBF" stroke-width="15" r="15" cx="160" cy="65">
        <animate attributeName="cy" calcMode="spline" dur="1" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1"
          repeatCount="indefinite" begin="0"></animate>
      </circle>
    </svg>
  </div>
</template>


<script>

export default {
  props: {
    isCover: {
      type: Boolean,
      default: () => false,
    },
    size: {
      type: String,
      default: () => '120px',
    },
  },
};
</script>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0px;
}

.loader.cover {
  height: calc(100dvh - 200px);
}
</style>
