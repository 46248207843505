var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "image-uploader"
  }, [_c('div', {
    staticClass: "actions",
    on: {
      "click": function ($event) {
        _vm.selectedFile ? _vm.onRemoveFile() : _vm.$emit('on-show-sample');
      }
    }
  }, [_c('i', {
    class: _vm.selectedFile ? 'fa fa-times' : 'fa fa-info'
  })]), _c('div', {
    staticClass: "content"
  }, [_c('input', {
    ref: "fileInput",
    attrs: {
      "type": "file",
      "accept": "image/*",
      "hidden": "",
      "max-size": 5000000
    },
    on: {
      "change": _vm.onFileSelected
    }
  }), !_vm.selectedFile ? _c('div', {
    staticClass: "upload",
    on: {
      "click": function ($event) {
        return _vm.$refs.fileInput.click();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/zadi/upload-file.png")
    }
  }), _c('p', [_vm._v("إرفاق الملف")])]) : _c('div', {
    staticClass: "preview"
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader"
  }, [_c('CoverLoader')], 1) : _vm._e(), _c('img', {
    attrs: {
      "src": _vm.previewUrl
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }