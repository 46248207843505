<template>
  <div class="image-uploader">
    <div class="actions" @click="selectedFile ? onRemoveFile() : $emit('on-show-sample')">
      <i :class="selectedFile ? 'fa fa-times' : 'fa fa-info'"></i>
    </div>
    <div class="content">
      <input type="file" @change="onFileSelected" ref="fileInput" accept="image/*" hidden :max-size="5000000">
      <div v-if="!selectedFile" @click="$refs.fileInput.click()" class="upload">
        <img src="../../../../../public/assets/images/website/zadi/upload-file.png" />
        <p>إرفاق الملف</p>
      </div>
      <div v-else class="preview">
        <div class="loader" v-if="isLoading">
          <CoverLoader />
        </div>
        <img :src="previewUrl">
      </div>
    </div>
  </div>
</template>

<script>
import ShareEntityRepository from '../../repositories/share-entity';
import CoverLoader from "../shared/CoverLoader.vue";

export default {
  components: {
    CoverLoader,
  },
  data() {
    return {
      selectedFile: null,
      previewUrl: null,
      isLoading: false,
    }
  },
  methods: {
    onRemoveFile() {
      this.$refs.fileInput.value = null;
      this.selectedFile = null;
      this.previewUrl = null;
      this.$emit('on-image-upload', '');
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      this.previewFile();
      this.uploadFile();
    },
    previewFile() {
      if (this.selectedFile && this.selectedFile.type.startsWith('image/')) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.previewUrl = e.target.result
        }
        reader.readAsDataURL(this.selectedFile)
      } else {
        this.previewUrl = null
      }
    },
    async uploadFile() {
      try {
        this.isLoading = true;
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        const response = await ShareEntityRepository.uploadImage(formData);
        this.$emit('on-image-upload', response.data?.file || '');
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.image-uploader {
  border: 2px dashed #9DD8D4;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
  height: 190px;
  position: relative;
  margin-bottom: 30px;
}

.image-uploader .content,
.image-uploader .preview {
  width: 100%;
  height: 100%;
}

.image-uploader .preview {
  position: relative;
  display: flex;
  justify-content: center;
}

.image-uploader .preview .loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.image-uploader .preview img {
  border-radius: 8px;
}

.image-uploader .upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.image-uploader .upload p {
  font-weight: 600;
}

.image-uploader .upload img {
  transition: all 0.2s ease-in-out;
}

.image-uploader .upload:hover img {
  scale: 1.1;
}

.image-uploader .actions {
  position: absolute;
  top: -12px;
  left: -6px;
  background: #ffffff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in-out;
}

.image-uploader .actions:hover {
  scale: 1.1;
}
</style>
