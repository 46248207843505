var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['loader', _vm.isCover ? 'cover' : null]
  }, [_c('svg', {
    style: {
      width: _vm.size
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 200 200"
    }
  }, [_c('circle', {
    attrs: {
      "fill": "#F4ACBF",
      "stroke": "#F4ACBF",
      "stroke-width": "15",
      "r": "15",
      "cx": "40",
      "cy": "65"
    }
  }, [_c('animate', {
    attrs: {
      "attributeName": "cy",
      "calcMode": "spline",
      "dur": "1",
      "values": "65;135;65;",
      "keySplines": ".5 0 .5 1;.5 0 .5 1",
      "repeatCount": "indefinite",
      "begin": "-.4"
    }
  })]), _c('circle', {
    attrs: {
      "fill": "#F4ACBF",
      "stroke": "#F4ACBF",
      "stroke-width": "15",
      "r": "15",
      "cx": "100",
      "cy": "65"
    }
  }, [_c('animate', {
    attrs: {
      "attributeName": "cy",
      "calcMode": "spline",
      "dur": "1",
      "values": "65;135;65;",
      "keySplines": ".5 0 .5 1;.5 0 .5 1",
      "repeatCount": "indefinite",
      "begin": "-.2"
    }
  })]), _c('circle', {
    attrs: {
      "fill": "#F4ACBF",
      "stroke": "#F4ACBF",
      "stroke-width": "15",
      "r": "15",
      "cx": "160",
      "cy": "65"
    }
  }, [_c('animate', {
    attrs: {
      "attributeName": "cy",
      "calcMode": "spline",
      "dur": "1",
      "values": "65;135;65;",
      "keySplines": ".5 0 .5 1;.5 0 .5 1",
      "repeatCount": "indefinite",
      "begin": "0"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }